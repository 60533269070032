
#contacts {
    background-image: url('background.png');
    background-repeat: no-repeat;
    background-size: cover; 
	position: relative;
	text-align: center;
	/*height: deskHeight;*/
  } 

#contacts a:link, #contacts a{
	/*background-color:#ffc070;*/
	transition:color 1s;
	text-decoration: none;
	-moz-transition: color 1s; /* Firefox 4 */
	-webkit-transition: color 1s; /* Safari & Chrome */
	-o-transition: color 1s; /* Opera */
}

#contacts a:hover {
  background-color:#ee922b;
}




#contacts .borderposition{
	/*-webkit-perspective-origin: 50% 50%;*/
	
    width: 40%;
    position: relative;
    top: 20%;
	left:30%;
	overflow:hidden;
}


#contacts .border{
	border: 5px solid #fff;
	font-family:'Microsoft Yahei', '微软雅黑';
	font-size:3vw;
	color: #ffffff;
	text-align: center;
	padding:20px 25px;
}

#contacts .left{
	position:absolute;
	top:45%;
	left:20%;
}

#contacts .boxleft{
	border: 5px solid #ffc070;
	border-radius:30px;
	background-color:#ffc070;
	position:absolute;
	top:45%;
	left:20%;
	width:22vw;
	height:18vw;
	text-align:center;
}



#contacts .email{
	width: 40%;
	position:absolute;
	top:13%;
	left:30%;
}

#contacts .boxleftspan {
	font-family:'Roboto Condensed';
	color: #ffffff;
	font-size:1.5vw;
	font-weight:600;
	/*overflow: hidden;*/
}

#contacts .right{
	position:absolute;
	top:45%;
	right:20%;
}

#contacts .boxright{
	border: 5px solid #ffc070;
	border-radius:30px;
	background-color:#ffc070;
	position:absolute;
	top:45%;
	right:20%;
	width:22vw;
	height:18vw;
	text-align:center;

}



#contacts .locate{
	width: 40%;
	position:absolute;
	top:13%;
	left:30%;
}

#contacts .boxrightspan {
	font-family:'Roboto Condensed';
	color: #ffffff;
	font-size:1.5vw;
	font-weight:600;
	/*overflow: hidden;*/
}




#contacts .spanpostion{
	position: relative;
	top:65%;
}


#contacts .foot{
	position:absolute;
	top:92%;
	left:50%;
	width:450px; /*change to one line*/
	height:30px;
	display: flex;
	flex-flow: column;
	text-align: center;
	-moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#contacts .footimage{
	width:60px;
	margin-top:1vw;
	/* position:absolute;
	left:40%;
	top:100%; */
}

#contacts .footerposition{
	/* position:absolute;
	bottom:20%; */
}

#contacts .footer{
	color:#fff;
	font-family:'Microsoft Yahei', '微软雅黑';
	text-align:center;
}

@media only screen and (max-width: 961px){
#contacts .boxleft{
	width:24vw;
	height:20vw;
}
#contacts .boxright{
	width:24vw;
	height:20vw;
}

/* #contacts .footer{
	font-size: 11.5px;
}

#contacts .footimage{
	left:33%;
}

#contacts .foot{
	left:58%;
} */
}

@media only screen and (max-width: 600px){
#contacts .boxleft{
	width:26vw;
	height:23vw;
}
#contacts .boxright{
	width:26vw;
	height:23vw;
}
#contacts .borderposition{
	width:44%;
	left:30%;
}
#contacts .footer{
	font-size: 11px;
}

#contacts .footimage{
	margin-top:3vw;
}
/*
#contacts .foot{
	left:58%;
} */

}
/* @media only screen and (max-width: 500px){
	#contacts .footer{
		font-size: 10px;
	}
	
	#contacts .footimage{
		left:26%;
	}
	
	#contacts .foot{
		left:65%;
	}
} */
@media only screen and (max-width: 400px){
	/* #contacts .footer{
		font-size: 10px;
	}
	
	#contacts .footimage{
		left:26%;
	}
	
	#contacts .foot{
		left:67%;
	} */


#contacts .boxleft{
	border: 5px solid #ffc070;
	border-radius:30px;
	background-color:#ffc070;
	position:absolute;
	top:45%;
	left:10%;
	width:80%;
	height:15%;
	text-align:center;
}
#contacts .left{
	all:initial;
}


#contacts .right{
	all:initial;
}
#contacts .boxcontainer{
	display: flex;
}
#contacts .boxright{
	border: 5px solid #ffc070;
	border-radius:30px;
	background-color:#ffc070;
	position:absolute;
	top:65%;
	left:10%;
	width:80%;
	height:15%;
	text-align:center;
}

#contacts .borderposition{
	width:80%;
	left:10%;
}
#contacts .border{
	font-size:6vw;
}
#contacts .email{
	display:none;
}
#contacts .locate{
	display:none;
}
#contacts .boxleftspan {
	font-size: 4vw;
}
#contacts .boxrightspan {
	font-size: 4vw;
}
#contacts .spanpostion{
	top:45%;
}



}

/* @-webkit-keyframes fadeInRightBig {
	from {
	  opacity: 0;
	  -webkit-transform: translate3d(2000px, 0, 0);
	  transform: translate3d(2000px, 0, 0);
	}
  
	to {
	  opacity: 1;
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	}
  }
  
  @keyframes fadeInRightBig {
	from {
	  opacity: 0;
	  -webkit-transform: translate3d(2000px, 0, 0);
	  transform: translate3d(2000px, 0, 0);
	}
  
	to {
	  opacity: 1;
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	}
  }
  
#contacts .fadeInRightBig {
	-webkit-animation-name: fadeInRightBig;
	animation-name: fadeInRightBig;
  }

  @-webkit-keyframes fadeInLeftBig {
	from {
	  opacity: 0;
	  -webkit-transform: translate3d(-2000px, 0, 0);
	  transform: translate3d(-2000px, 0, 0);
	}
  
	to {
	  opacity: 1;
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	}
  }
  
  @keyframes fadeInLeftBig {
	from {
	  opacity: 0;
	  -webkit-transform: translate3d(-2000px, 0, 0);
	  transform: translate3d(-2000px, 0, 0);
	}
  
	to {
	  opacity: 1;
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	}
  }
  
#contacts .fadeInLeftBig {
	-webkit-animation-name: fadeInLeftBig;
	animation-name: fadeInLeftBig;
  }

#contacts.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
  } */



