* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: black;
}

html, body {
  margin: 0;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
  /* overflow-y: hidden; */
}

.component {
  height: 100%;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
}

.page-comp {
  height: 100vh;
  /* border: 1px black solid; */
}

@media only screen and (max-height: 400px) {
  .page-comp {
    height: 400px;
  }
}


#header {
  z-index: 1000;
  width: 100vw;
  height: 80px;
  display: flex;
  justify-content: space-between;

  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 30px;
  /* margin: 40px; */
  /* padding:  20px; */
  /* border: 1px dashed black;  */

  position: fixed;
  top: 0px; 
  background-color: white;
}

#header .logo {
  height: 25px;
}

/* .menu {
  display: flex;
  justify-content: space-between;
} */






/* #header a:link, #header a {
  color: #000000;
  transition:color 1s;
  text-decoration: none;
  -moz-transition: color 1s; 
  -webkit-transition: color 1s; 
  -o-transition: color 1s; 

} */

/* #header a:hover {
  color:#bdbdbd;
} */

@media only screen and (max-width: 400px) {
  #header {
    height: 50px;
    padding: 15px;
  }
}

@media only screen and (min-width: 961px) {
  #header .pager > div {
    font-family:"Microsoft YaHei UI";
    font-weight:1000;
    font-size: 18px;
    vertical-align: middle;
    display: flex;
    align-content: center;
    list-style-type: none;
  }

  #header .pager li {
  
    /* display: inline; */
    margin: auto;
    margin-right: 35px;
    margin-bottom:-5px;
  }
  
  #header .pager li:last-of-type {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 960px) {
  #header .small-menu {
    list-style: none;
    text-align: right;
    color: #ffffff;
  }

  #header .small-menu svg {
    margin-left: auto;
    margin-right: 0;
  }

  #header .small-menu li {
    padding: 5px;
    font-family:"Microsoft YaHei UI";
    font-weight:1000;
    border-bottom: 1px white solid;
    background-color: #FFA04B;

    /* width: 100vw; */
  }

  #header .small-menu li:last-of-type {
    border-bottom: none;
  }

  #header a {
    color: #ffffff;
  }
}

