

#history {
    position: relative;
}

#history .left-words {
    margin-top: 280px;
    margin-left: 80px;
}

#history .black-big-word {
    font-family: 'Microsoft Yahei', '微软雅黑', Arial, sans-serif; font-weight: 700;
    font-size: 4vw;
    text-align: left;
}

#history .orange-big-word {
    color:#FFA04B; 
    font-family: 'Chalkduster';
    font-size: 4.5vw;
    text-align: left;
}

#history .small-words {
    font-family: 'Microsoft Yahei', '微软雅黑', Arial, sans-serif; font-weight: 700;
    font-size: 1.8vw;
    text-align: left;
}

#history .orange {
    color:#FFA04B; 
    font-family: 'Chalkduster';
    font-size: 1.8vw;
    text-align: left;
}

#history .bgimage{
    position: absolute;
    left: 25%;
    right: 0;
    top: 20%;
    bottom: 3%;
    background-image: url(image.png);
    background-blend-mode: multiply;
    background-attachment: scroll;
    background-position: right center; 
    background-repeat: no-repeat; 
    background-size: cover;
    z-index: -1;
    -moz-clip-path: polygon(0vw 0vw, 7vw 20vw, 0vw 40vw, 80vw 40vw, 80vw 0vw);
    -ms-clip-path: polygon(0vw 0vw, 7vw 20vw, 0vw 40vw, 80vw 40vw, 80vw 0vw);
    -webkit-clip-path: polygon(0vw 0vw, 7vw 20vw, 0vw 40vw, 80vw 40vw, 80vw 0vw);
    clip-path: polygon(0vw 0vw, 7vw 20vw, 0vw 40vw, 80vw 40vw, 80vw 0vw);
}

#history .mask{

    background: #F49D31;
    opacity: 0.9;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

}

#history .mask2 {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
#history .total201711 {
    position: absolute;
    left: 10%;
    top: 8%;
}


#history .total20189 {
    position: absolute;
    left: 55%;
    top: 8%;
}

#history .total20183 {
    position: relative;
    margin-left: 12vw;
    margin-top: 19.5vw;
}

#history .total20199 {
    position: absolute;
    left: 45vw;
    top: 19.5vw;
}

#history .arrow {
    width: 4vw;
    height: 4vw;
}

#history .title {
    font-family:'Microsoft Yahei', '微软雅黑';
    font-size: 4vw;
    color: #ffffff
}


#history .detail {
    margin-left: 5vw;
    font-family: 'Roboto Condensed';
    font-size: 1.8vw;
    color: #ffffff;
    line-height: 35pt;

}



@media screen and (max-width: 1050px) {
    #history .left-words {
        position: relative;
        top: -180px;
        left: 40vw;
    }
    
    #history .bgimage{
        position: absolute;
        left: 0;
        right: 0;
        top: 40%;
        bottom: 0;
        background-image: url(image.png);
        background-blend-mode: multiply; 
        background-attachment: scroll;
        background-repeat: no-repeat; 
        background-size: cover;
        z-index: -1;
        -moz-clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
        -ms-clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
        -webkit-clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
        clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
    }
  

    #history .total20199 {
        position: absolute;
        left: 67vw;
        top: 22vw;
    }

    #history .detail {
        margin-left: 5vw;
        font-family: 'Roboto Condensed';
        font-size: 1.8vw;
        color: #ffffff;
        line-height: 18pt;
    
    }
    
}

@media screen and (max-width: 950px) {
    #history .left-words {
        position: relative;
        top: -170px;
        left: 40vw;
    }
    
    #history .bgimage{
        position: absolute;
        left: 0;
        right: 0;
        top: 40%;
        bottom: 0;
        background-image: url(image.png);
        background-blend-mode: multiply; 
        background-attachment: scroll;
        background-repeat: no-repeat; 
        background-size: cover;
        z-index: -1;
        -moz-clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
        -ms-clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
        -webkit-clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
        clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
    }
  
    #history .total20183 {
        position: relative;
        margin-left: 15vw;
        margin-top: 22vw;
    }
    
    #history .total20199 {
        position: absolute;
        left: 67vw;
        top: 22vw;
    }

    #history .arrow {
        width: 5.5vw;
        height: 5.5vw;
    }
    
    #history .title {
        font-family:'Roboto Condensed';
        font-size: 5.5vw;
        color: #ffffff
    }
    
    
    #history .detail {
        margin-left: 5vw;
        font-family: 'Roboto Condensed';
        font-size: 2.2vw;
        color: #ffffff;
        line-height: 18pt;
    
    }
    
}

@media screen and (max-width: 650px) {
    #history .left-words {
        position: relative;
        top: -140px;
        left: 40vw;
    }
    
    #history .bgimage{
        position: absolute;
        left: 0;
        right: 0;
        top: 40%;
        bottom: 0;
        background-image: url(image.png);
        background-blend-mode: multiply; 
        background-attachment: scroll;
        background-repeat: no-repeat; 
        background-size: cover;
        z-index: -1;
        -moz-clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
        -ms-clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
        -webkit-clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
        clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
    }
  
    #history .total201711 {
        position: absolute;
        left: 10%;
        top: 8%;
    }
    
    #history .total20189 {
        position: absolute;
        left: 57%;
        top: 25%;
    }
    
    #history .total20183 {
        position: relative;
        margin-left: 10vw;
        margin-top: 35vw;
    }
    
    #history .total20199 {
        position: absolute;
        left: 57vw;
        top: 47vw;
    }

    #history .arrow {
        width: 6vw;
        height: 6vw;
    }
    
    #history .title {
        font-family:'Roboto Condensed';
        font-size: 6vw;
        color: #ffffff
    }
    
    
    #history .detail {
        margin-left: 5vw;
        font-family: 'Roboto Condensed';
        font-size: 3vw;
        color: #ffffff;
        line-height: 18pt;
    
    }
    
}

@media screen and (max-width: 400px) {
    #history .left-words {
        position: relative;
        top: -200px;
        left: 18vw;
    }

    #history .black-big-word {
        font-family: 'Microsoft Yahei', '微软雅黑', Arial, sans-serif; font-weight: 700;
        font-size: 8vw;
        text-align: left;
    }
    
    #history .orange-big-word {
        color:#FFA04B; 
        font-family: 'Chalkduster';
        font-size: 9vw;
        text-align: left;
    }
    
    #history .small-words {
        font-family: 'Microsoft Yahei', '微软雅黑', Arial, sans-serif; font-weight: 700;
        font-size: 3.8vw;
        text-align: left;
    }
    
    #history .orange {
        color:#FFA04B; 
        font-family: 'Chalkduster';
        font-size: 1.8vw;
        text-align: left;
    }

    #history .bgimage{
        position: absolute;
        left: 0;
        right: 0;
        top: 40%;
        bottom: 0;
        background-image: url(image.png);
        background-blend-mode: multiply; 
        background-attachment: scroll;
        background-repeat: no-repeat; 
        background-size: cover;
        z-index: -1;
        -moz-clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
        -ms-clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
        -webkit-clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
        clip-path: polygon(0vw 0vw, 0vw 400vw, 800vw 400vw, 800vw 0vw);
    }
  
    #history .total201711 {
        position: absolute;
        left: 10%;
        top: 8%;
    }
    
    #history .total20189 {
        position: absolute;
        left: 57%;
        top: 18%;
    }
    
    #history .total20183 {
        position: relative;
        margin-left: 10vw;
        margin-top: 40vw;
    }
    
    #history .total20199 {
        position: absolute;
        left: 57vw;
        top: 50vw;
    }

    #history .arrow {
        width: 6vw;
        height: 6vw;
    }
    
    #history .title {
        font-family:'Roboto Condensed';
        font-size: 6vw;
        color: #ffffff
    }
    
    
    #history .detail {
        margin-left: 5vw;
        font-family: 'Roboto Condensed';
        font-size: 3vw;
        color: #ffffff;
        line-height: 18pt;
    
    }
    
}




