#services .main{
    width: 100%;
    position: relative;
    top: 10vh;
    font-family: 'Roboto Condensed';
}

#services .title{
    font-family: 'Chalkduster';
    color:#FFA04B; 
    font-size: 15vw;
    text-align: center;
}

#services .ahead {
    font-family: 'Chalkduster';
    color:#FFA04B; 
}

#services .more {
    color:#FFA04B;
}


#services .slogan{
    font-size: 3vw;
    text-align: center;
    font-family: 'Roboto Condensed';
}


#services .logo {
    display: block;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

#services .card-content {
    text-align: center;

}

#services .card-content h1 {
    font-family: 'Roboto Condensed';
}

#services .card {
    padding-top: 20px;
    margin-top: 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

#services .card:hover {
    background-color: oldlace;
}




@media only screen and (min-width: 401px)  {
    #services .title {
        font-family: 'Chalkduster';
        color:#FFA04B; 
        font-size: 60px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-size: 7vw;
    }
    

    
    #services .main {
        margin-left: auto;
        margin-right: auto;
        margin-top: 5vh;
        /* margin-top: auto;
        margin-bottom: auto; */
        text-align: center;
        
    }
    
    #services .card-container {
        display: flex;
        justify-content: center;
    }
    
    #services .card {
        width: 30vw;
        height: 30vw;

        padding: 20px;
        margin: 10vw;
        margin-top: 5vw;
        text-align: center;
        display: flex;
    }
    
    #services .card-content {
        margin: auto;
    }
    
    #services .logo {
        width: 30vw;
        height: 30vw;
        max-width: 350px;
        max-height: 350px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }
    
    #services .slogan {
        font-size: 1.5vw;
    }

    @media only screen and (min-width: 961px) {
        #services .title {
            font-size: 5vw;
        }

        #services .slogan {
            font-size: 1.5vw;
        }
    }
    
} 

