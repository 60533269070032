@font-face {
  font-family: 'Chalkduster';
  src: url('./static/Chalkduster.woff') format('woff');
}

* {
  font-family:  'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
