#home {
    /* position: absolute; */
    background: rgba(0,0,0,0.6);
    background: -moz-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0.58) 52%, rgba(0,0,0,0) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0.6)), color-stop(50%, rgba(0,0,0,0.6)), color-stop(52%, rgba(0,0,0,0.58)), color-stop(100%, rgba(0,0,0,0)));
    background: -webkit-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0.58) 52%, rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0.58) 52%, rgba(0,0,0,0) 100%);
    background: -ms-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0.58) 52%, rgba(0,0,0,0) 100%);
    background: linear-gradient(to right, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0.58) 52%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );

    
} 

#home .background {
    width: 100%;
    object-fit: cover;
    z-index: -99;
}

#home .content-container {
    font-family: 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
    width: 100%;

    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 25%;
}

#home .unlock-container{

    text-align: center;
    font-size: 11vw;
    color: white;
    width: 100%;
    margin-bottom: 20px;
}

#home .large-logo-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#home .large-logo {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}


#home .unlock {
    font-size: 6vw;
}

#home .slogan {
    width: 100%;
    font-size: 7vw;
    text-align: center;
    color: white;
}

#home .orange {
    color:#FFA04B; 
    font-family: 'Chalkduster';
}


@media only screen and (min-width: 401px) and (max-width: 960px) {
    #home .content-container{
        top: 13vh;
    }

    #home .unlock-container{

        font-size: 8vw;
    }

    #home .unlock{
        font-size: 7vw;
    }

    #home .large-logo{
        width: 40vw;
        margin-bottom: 3vw;
    }

    #home .slogan{
        font-size: 7vw;
    }


}

@media only screen and (min-width: 961px) {
    #home .content-container{
        margin-top: auto;
        margin-bottom: auto;
    }


    #home .large-logo-container {
        position: absolute;
        left: 8%;
        top: 13%;
        width: 23%;
        /* filter: drop-shadow(0 0 500px rgb(0, 0, 0)); */
    
    }
    
    #home .large-logo {
        width: 100%;
    }
    
    #home .title {
        position: absolute;
    
    }
    
    #home .slogan {
        width: 100%;
        font-size: 4.5vw;
        text-align: center;
        color: white;
    }
    
    
    
    #home .orange {
        color:#FFA04B; 
        font-family: 'Chalkduster';
    }
    
    #home .unlock-container {
        position: absolute;
        left: 40%;
        top: 15%;
        width: 40%;
        font-size: 4.5vw;
        color: white;
    }
    
    #home .unlock {
        font-size: 3vw;
    }

}
