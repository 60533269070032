

#about-us {
    display: block;
}

#about-us {

    *{
        margin: 0;
        padding: 0;
    }

    .yhbold{
        font-family: 'Microsoft Yahei', '微软雅黑', Arial, sans-serif; font-weight: 700;
        font-weight: bolder;
    }



    /* 左侧栏 */
    .leftblock{
        float: left;
        padding: 45px 0;
        height: 533px;
        width: 690px;
        margin-left: 30px;
        position: relative;
        bottom: -180px;
        overflow: hidden;
    }
    .leftblock::after{
        content:"";
        clear:both;
        display:block;   
    }
    /* 标题 */
    .leftcaption{
        position: absolute;
        top: 20px;
        left: 20px;
    }
    .smalltitle{
        font-size: 22px;
        position: absolute;
    } 

    .ahead{
        font-family:'Chalkduster';   
        margin-left: 8px;
        color: #f49d31;
    
    }

    .bigtitle{
        font-size: 68px;
        position: absolute;
        margin-top: 10px;
    }

    .we{
        margin: 0 43px;
        font-family:'Chalkduster'; 
        font-size: 100px;
        color: #f49d31;
        font-weight: 400;
    }
    /* 左侧内容 */
    .leftcontent{
        position: absolute;
        top: 180px;
        // font-weight: bold;
        font-family: 'Roboto Condensed';
        font-size: 24px;
        padding: 0 35px;
        /* text-align: justify; */
    }
    .leftcontent div{
        margin: 25px 0;
    }





    /* 右侧栏 */
    .rightblock{
        position: relative;
        bottom: -140px;
        height: 572.5px;
        overflow: hidden;
    }

    .backgroundIMG{
    width: 690px;
    height: 533px;
    z-index: -1;
    position: absolute;
    right: 30px;
    top: 40px;
    background-image: url(StudentsIMG.jpg);
    background-size: 100% 100%;
    }


    .backgroundcolor{
    height: 533px;
    width: 690px;
    background-color:rgba(255,169,38,0.88);
    z-index: 1;
    position: absolute;
    right: 30px;
    top: 40px;
    }

    .rightcaption{
        position:absolute;
        right: 0px;
        top: 170px;
        color: #fff;
        z-index: 2;
    }

    .righttitle{
        font-size: 60px;
        margin-bottom: 20px;
        margin-top: 60px;
        text-align: right;
        margin-right: 50px;
    }

    .rightnote{
        font-size: 25px;
        margin-right:50px ;
        text-align: right;
    }


    /* 手机屏幕设计 */
    @media only screen and  (max-width: 400px) and (min-width: 380px){
        @media only screen and (min-height:670px){
        /* 右侧变动 */
        .rightblock{
            width: 690px;
            height: 165px;
    
        }
        .backgroundIMG{
            background-image: url(StudentsIMGs.jpg);
            background-size: 400px 165px;
            margin-top: -40px;
        }
        .backgroundcolor{
            margin-top: -40px;
        }
        
        .righttitle{
            position: absolute;
            top: -195px;
            left: -260px;
            margin-left: 70px;
            font-size: 28px;
            z-index: 3;
        }

        .rightnote{
            font-size: 15px;
            position: relative;
            top: -90px;
            left: -290px;
        }

        /* 左侧变动 */
        .leftcaption{
            margin-top: 10px;
        }

        .smalltitle{
            font-size: 16px;
            margin-left: 12px;
        }
        .bigtitle{
            font-size: 40px;
            margin-left: 15px;
            margin-top: 25px;
        }
        .we{
            font-size: 60px;
            margin: 0 20px;
        }
        .leftcontent{
            width: 380px;
            font-size: 16px;
            
        }
        .leftcontent{
            margin: 0 auto;
            padding: 0 15px;
            position: absolute;
            top: 140px;
        }

        .leftblock{
            margin-left: 15px;
            position: relative;
            bottom: -120px;
            height: 400px;
        }
    }
        @media only screen and (max-height:670px)and (min-height:615px){
        .rightblock{
            width: 690px;
            height: 165px;
    
        }
        .backgroundIMG{
            background-image: url(StudentsIMGs.jpg);
            background-size: 400px 165px;
            margin-top: -40px;
        }
        .backgroundcolor{
            margin-top: -40px;
        }
        
        .righttitle{
            position: absolute;
            top: -195px;
            left: -260px;
            margin-left: 70px;
            font-size: 28px;
            z-index: 3;
        }

        .rightnote{
            font-size: 15px;
            position: relative;
            top: -90px;
            left: -290px;
        }

        /* 左侧变动 */
        
        .smalltitle{
            font-size: 16px;
            margin-left: 12px;
        }
        .bigtitle{
            font-size: 40px;
            margin-left: 15px;
        }
        .we{
            font-size: 60px;
            margin: 0 20px;
        }
        .leftcontent{
            width: 380px;
            font-size: 16px;
            
        }
        .leftcontent{
            margin: 0 auto;
            padding: 0 15px;
            position: absolute;
            top: 120px;
        }

        .leftblock{
            margin-left: 15px;
            position: relative;
            bottom: -120px;
            height: 360px;
        }

    }
        @media only screen and (max-height:615px){
        /* 右侧变动 */
        .rightblock{
            width: 690px;
            height: 165px;
    
        }
        .backgroundIMG{
            background-image: url(StudentsIMGs.jpg);
            background-size: 400px 165px;
            margin-top: -40px;
        }
        .backgroundcolor{
            margin-top: -40px;
        }
        
        .righttitle{
            position: absolute;
            top: -195px;
            left: -260px;
            margin-left: 70px;
            font-size: 28px;
            z-index: 3;
        }

        .rightnote{
            font-size: 15px;
            position: relative;
            top: -90px;
            left: -290px;
        }

        /* 左侧变动 */
        .smalltitle{
            font-size: 16px;
            margin-left: 12px;
        }
        .bigtitle{
            font-size: 40px;
            margin-left: 15px;
        }
        .we{
            font-size: 60px;
            margin: 0 20px;
        }
        .leftcontent{
            width: 380px;
            font-size: 16px;  
        }
        .leftcontent{
            margin: 0 auto;
            padding: 0 15px;
            position: absolute;
            top: 110px;
        }

        .leftblock{
            margin-left: 15px;
            position: relative;
            bottom: -120px;
            height: 330px;
        }
    }

    } 

    @media only screen and (max-width: 379px){
        @media only screen and (min-height:670px){
        /* 右侧变动 */
        .rightblock{
            width: 690px;
            height: 165px;
    
        }
        .backgroundIMG{
            background-image: url(StudentsIMGs.jpg);
            background-size: 400px 165px;
            margin-top: -40px;
        }
        .backgroundcolor{
            margin-top: -40px;
        }
        
        .righttitle{
            position: absolute;
            top: -195px;
            left: -240px;
            margin-left: 70px;
            font-size: 28px;
            z-index: 3;
        }

        .rightnote{
            font-size: 15px;
            position: relative;
            top: -90px;
            left: -290px;
        }

        /* 左侧变动 */
        .leftcaption{
            margin-top: 10px;
        }

        .smalltitle{
            font-size: 16px;
            margin-left: 12px;
        }
        .bigtitle{
            font-size: 40px;
            margin-left: 15px;
            margin-top: 25px;
        }
        .we{
            font-size: 60px;
            margin: 0 20px;
        }
        .leftcontent{
            width: 360px;
            font-size: 16px;
            
        }
        .leftcontent{
            margin: 0 auto;
            padding: 0 15px;
            position: absolute;
            top: 140px;
        }

        .leftblock{
            margin-left: 15px;
            position: relative;
            bottom: -120px;
            height: 400px;
        }
    }
        @media only screen and (max-height:670px)and (min-height:615px){
        .rightblock{
            width: 690px;
            height: 165px;
    
        }
        .backgroundIMG{
            background-image: url(StudentsIMGs.jpg);
            background-size: 400px 165px;
            margin-top: -40px;
        }
        .backgroundcolor{
            margin-top: -40px;
        }
        
        .righttitle{
            position: absolute;
            top: -195px;
            left: -260px;
            margin-left: 70px;
            font-size: 28px;
            z-index: 3;
        }

        .rightnote{
            font-size: 15px;
            position: relative;
            top: -90px;
            left: -290px;
        }

        /* 左侧变动 */
        
        .smalltitle{
            font-size: 16px;
            margin-left: 12px;
        }
        .bigtitle{
            font-size: 40px;
            margin-left: 15px;
        }
        .we{
            font-size: 60px;
            margin: 0 20px;
        }
        .leftcontent{
            width: 360px;
            font-size: 16px;
            
        }
        .leftcontent{
            margin: 0 auto;
            padding: 0 15px;
            position: absolute;
            top: 120px;
        }

        .leftblock{
            margin-left: 15px;
            position: relative;
            bottom: -120px;
            height: 360px;
        }

    }
        @media only screen and (max-height:615px){
        /* 右侧变动 */
        .rightblock{
            width: 690px;
            height: 165px;
    
        }
        .backgroundIMG{
            background-image: url(StudentsIMGs.jpg);
            background-size: 400px 165px;
            margin-top: -40px;
        }
        .backgroundcolor{
            margin-top: -40px;
        }
        
        .righttitle{
            position: absolute;
            top: -195px;
            left: -260px;
            margin-left: 70px;
            font-size: 28px;
            z-index: 3;
        }

        .rightnote{
            font-size: 15px;
            position: relative;
            top: -90px;
            left: -290px;
        }

        /* 左侧变动 */
        .smalltitle{
            font-size: 16px;
            margin-left: 12px;
        }
        .bigtitle{
            font-size: 40px;
            margin-left: 15px;
        }
        .we{
            font-size: 60px;
            margin: 0 20px;
        }
        .leftcontent{
            width: 360px;
            font-size: 16px;  
        }
        .leftcontent{
            margin: 0 auto;
            padding: 0 15px;
            position: absolute;
            top: 110px;
        }

        .leftblock{
            margin-left: 15px;
            position: relative;
            bottom: -120px;
            height: 330px;
        }
    }

    } 

    /* PC端交互 */
    @media only screen and (max-width: 1200px) and (min-width:1100px){
        .leftblock{
            width: 600px;
        }

    }

    @media only screen and (max-width: 1100px) and (min-width:1000px){
        .leftblock{
            width: 500px;
        }
        .smalltitle{
            font-size: 18px;
        }
        .bigtitle{
            font-size: 60px;
        }
        .we{
            font-size: 88px;
            margin: 0 30px;
        }
        .leftcontent{
            font-size: 22px;
            margin-top: -30px;
        }
    }

    @media only screen and (max-width: 1000px) and (min-width:900px){
        .leftblock{
            width: 450px;
        }
        .smalltitle{
            font-size: 16px;
        }
        .bigtitle{
            font-size: 50px;
        }
        .we{
            font-size: 80px;
            margin: 0 30px;
        }
        .leftcontent{
            font-size: 20px;
            margin-top: -30px;
        }
        .righttitle{
            font-size: 55px;
        }
        .rightnote{
            font-size: 23px;
        }
    }

    @media only screen and (max-width: 900px) and (min-width:800px){
        .leftblock{
            width: 400px;
        }
        .smalltitle{
            font-size: 16px;
        }
        .bigtitle{
            font-size: 45px;
            margin-top: 20px;
        }
        .we{
            font-size: 70px;
            margin: 0 30px;
        }
        .leftcontent{
            font-size: 20px;
            margin-top: -30px;
        }
        .righttitle{
            font-size: 55px;
        }
        .rightnote{
            font-size: 23px;
        }
    }

    @media only screen and (max-width: 800px) and (min-width:690px){
        .leftblock{
            width: 350px;
        }
        .smalltitle{
            font-size: 16px;
        }
        .bigtitle{
            font-size: 40px;
            margin-top: 20px;
        }
        .we{
            font-size: 65px;
            margin: 0 25px;
        }
        .leftcontent{
            font-size: 18px;
            margin-top: -30px;
        }
        .righttitle{
            font-size: 50px;
        }
        .rightnote{
            font-size: 20px;
        }
    }

    @media only screen and (max-width: 690px) and (min-width:600px){
        .leftblock{
            width: 320px;
        }
        .smalltitle{
            font-size: 14px;
        }
        .bigtitle{
            font-size: 35px;
            margin-top: 30px;
        }
        .we{
            font-size: 55px;
            margin: 0 25px;
        }
        .leftcontent{
            font-size: 16px;
            margin-top: -30px;
        }
        .righttitle{
            font-size: 40px;
        }
        .rightnote{
            font-size: 20px;
        }
    }

    @media only screen and (max-width: 600px)and (min-width:401px){
        .leftblock{
            width: 100%;
            height: 380px;
        }
        
        .smalltitle{
            font-size: 16px;
            margin-left: 30px;
        
        }
        .bigtitle{
            font-size: 42px;
            margin-top: 15px;
            margin-left: 60px;
        }
        .we{
            font-size: 64px;
            margin: 0 20px;
        }
        .leftcontent{
            font-size: 17px;
            margin-top: -70px;
            margin-right: 50px;
        }


        .righttitle{
            font-size: 40px;
        }

        .rightnote{
            font-size: 20px;
        }

        .rightblock{
            width: 690px;
            height: 270px;
        }

        .backgroundIMG{
            background-image: url(StudentsIMGs.jpg);
            background-size: 780px 270px;
        }
        
        .righttitle{
            font-size: 34px;
            z-index: 111;
            color: #fff;
            height: 40px;
            width: 300px;
            margin-left: 70px;
            text-align: right;
        }

        .rightnote{
            font-size: 18px;
            text-align: right;
        }

        .rightcaption{
            position: absolute;
            top: 15px;
            margin-right: 160px;
            text-align: right;
        }

    }
}