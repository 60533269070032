
.product {
    display: block;
    font-family: 'Roboto Condensed';
}

.product .logo {
    width: 100px;
    width: 30vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* position: -webkit-sticky;
    position: sticky; */

    /* position: absolute; */
}

.product .logo-container {
    margin: 50px;
    text-align: center;

}

.product .title {
    display: block;
    font-size: 30px;
    font-size: 10vw;
}
.product .code {
    width: 100px;
    width: 30vw;
    margin: 10px auto;
    display: block;
    /* position: absolute; */
    padding-bottom: 30px;
}

.product .intro {
    margin: 40px;
    margin: 10vw;
    font-size: 5vw;
}

.product .intro p {
    margin-bottom: 10px;
}

.product .back {
    font-size: 5vw;
    position: relative;
    top: 5vw;
    left: 5vw;
}

@media only screen and (min-width: 401px) and (max-width: 960px) {
    .product .intro {
        font-size: 3.4vw;
    }
}
@media only screen and (min-width: 961px) {
    .product .content-container {
        display: flex;
        flex-direction: column;
        margin-top: 150px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1300px;
    }

    .product .container-two {
        display: flex;
    }

    .product .logo-container {
        margin: 40px;
    }

    .product .intro {
        margin: 40px;
        margin-right: 100px;
        font-size: 23px;
        /* max-width: 700px; */
        /* position: absolute;
        left: 40%;
        top: 24%;
        right: 10%; */
        font-family: 'Roboto Condensed';
    }

    .product p {
        padding-bottom: 10px;
    }

    .product .title {
        padding-bottom: 10px;
        font-size: 3vw;
        margin-top: 30px;
    }

    .product .logo {
        width: 320px;
        margin: 0 30px;
        /* width: 150px; */
        /* left: 15%;
        top: 30%;
        position: absolute; */
    }

    .product .code {
        width: 150px;
        margin-right: 100px;

        /* width: 7%; */
        /* position: absolute;
        left: 80%;
        top: 80%; */
    }

    .product .back {
        /* top: 10%;
        left: 5%;
        position: absolute; */
        font-size: 30px;
        font-family: 'Roboto Condensed';

    }

}