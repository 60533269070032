
#vision {
    position: relative;
}

#vision .topbox{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 60%;
    text-align: center;
}

#vision .think{
    position: relative;
    font-size: 11pt;
    font-family: 'Microsoft Yahei', '微软雅黑', Arial, sans-serif; font-weight: 700;
}

#vision .ahead{
    position: relative;
    margin-right: -120px;
    font-size: 11pt;
    font-family: 'Chalkduster';
    color: #F49D31;
}

#vision .vision{
    position: relative;
    font-size: 50pt;
    font-family: 'Chalkduster';
    color: #F49D31;
    margin-top: -1vw;
    margin-bottom: 2vw;
}

#vision .text_1{
    position: relative;
    font-size: 19pt;
    bottom: 1vw;
    font-family: "Roboto Condensed";
}

#vision .text_2{
    display: none;
}

#vision .bottombox{
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
    bottom: 0vw;
    background-image: url(background.jpg);
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
}

#vision .mask{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url(mask.png);
    opacity: 0.9;
}


#vision .innovation{
    width: 30vw;
    height: 25vw;
    top: 3vw;
    left: 15vw;
    position: absolute;
    color: white;
    text-align: center;
}

#vision .word{
    position: relative;
    font-size: 18pt;
    font-family: 'Roboto Condensed';
    font-weight: 700;
}

#vision .in{
    position: relative;
    font-size: 15pt;
    font-family: 'Roboto Condensed';
    font-weight: 700;
}

#vision .i{
    position: relative;
    font-size: 13pt;
    font-family: 'Roboto Condensed';
}

#vision .light{
    position: relative;
    width: 160px;
    height: 160px;
}

#vision .technology{
    width: 30vw;
    height: 25vw;
    top: 3.5vw;
    right: 16vw;
    position: absolute;
    color: white;
    text-align: center;
}

#vision .computer{
    position: relative;
    width: 150px;
    height: 150px;
}

@media screen and (max-width: 1200px){
    
    #vision .innovation{
        width: 300px;
        height: 300px;
        top: 30px;
        left: 10vw;
        position: absolute;
        color: white;
        text-align: center;
    }
    
    #vision .technology{
        width: 300px;
        height: 300px;
        top: 40px;
        right: 10vw;
        position: absolute;
        color: white;
        text-align: center;
    }

}

@media screen and (max-width: 960px){

    #vision {
        position: relative;
    }
    
    #vision .topbox{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60%;
        top: 20%;
        text-align: center;
    }
    
    #vision .think{
        position: relative;
        font-size: 12pt;
        font-family: 'Microsoft Yahei', '微软雅黑', Arial, sans-serif; font-weight: 700;
    }
    
    #vision .ahead{
        position: relative;
        margin-right: -140px;
        font-size: 12pt;
        font-family: 'Chalkduster';
        color: #F49D31;
    }
    
    #vision .vision{
        position: relative;
        font-size: 55pt;
        font-family: 'Chalkduster';
        color: #F49D31;
        margin-top: -15px;
        margin-bottom: 1vw;
    }
    
    #vision .text_1{
        display: none;
    }

    #vision .text_2{
        display: block;
        position: relative;
        font-size: 19pt;
        bottom: 1vw;
        font-family: "Roboto Condensed";
    }
    
    #vision .bottombox{
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        bottom: 0vw;
        background-image: url(background.jpg);
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
       
    }
    
    #vision .mask{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #F49D31;
        opacity: 0.9;
    }
    
    
    #vision .innovation{
        width: 300px;
        height: 300px;
        top: 30px;
        left: 8vw;
        position: absolute;
        color: white;
        text-align: center;
    }
    
    #vision .word{
        position: relative;
        font-size: 20pt;
        font-family: 'Roboto Condensed'; font-weight: 700;
    }
    
    #vision .in{
        position: relative;
        font-size: 15pt;
        font-family: 'Roboto Condensed'; font-weight: 700;
    }
    
    #vision .i{
        position: relative;
        font-size: 12pt;
        font-family: 'Roboto Condensed';
    }
    
    #vision .light{
        position: relative;
        width: 130px;
        height: 130px;
    }
    
    #vision .technology{
        width: 300px;
        height: 300px;
        top: 40px;
        right: 8vw;
        position: absolute;
        color: white;
        text-align: center;
    }
    
    #vision .computer{
        position: relative;
        width: 120px;
        height: 120px;
    }  

}

@media screen and (max-width: 700px){
    
    #vision .innovation{
        width: 300px;
        height: 300px;
        top: 30px;
        left: 4vw;
        position: absolute;
        color: white;
        text-align: center;
    }
    
    #vision .technology{
        width: 300px;
        height: 300px;
        top: 40px;
        right: 4vw;
        position: absolute;
        color: white;
        text-align: center;
    }

}

@media screen and (max-width: 600px){
    
    #vision .innovation{
        width: 300px;
        height: 300px;
        top: 30px;
        left: 0vw;
        position: absolute;
        color: white;
        text-align: center;
    }
    
    #vision .technology{
        width: 300px;
        height: 300px;
        top: 40px;
        right: 0vw;
        position: absolute;
        color: white;
        text-align: center;
    }

}

@media screen and (max-width: 550px){
    
    #vision .innovation{
        width: 300px;
        height: 300px;
        top: 30px;
        left: -3vw;
        position: absolute;
        color: white;
        text-align: center;
    }

    #vision .in{
        position: relative;
        font-size: 14pt;
        width: 250px;
        left: 4vw;
        font-family: 'Roboto Condensed'; font-weight: 700;
    }

    #vision .i{
        position: relative;
        font-size: 10pt;
        top: -15px;
        font-family: 'Roboto Condensed';
    }
    
    #vision .technology{
        width: 300px;
        height: 300px;
        top: 40px;
        right: -3vw;
        position: absolute;
        color: white;
        text-align: center;
    }

}

@media screen and (max-width: 500px){
    
    #vision .innovation{
        width: 250px;
        height: 250px;
        top: 30px;
        left: -2vw;
        position: absolute;
        color: white;
        text-align: center;
    }
    
    #vision .word{
        position: relative;
        font-size: 17pt;
        font-family: 'Roboto Condensed'; font-weight: 700;
    }
    
    #vision .in{
        position: relative;
        font-size: 13pt;
        width: 200px;
        left: 5vw;
        font-family: 'Roboto Condensed'; font-weight: 700;
    }
    
    #vision .i{
        position: relative;
        font-size: 10pt;
        top: -15px;
        font-family: 'Roboto Condensed';
    }
    
    #vision .light{
        position: relative;
        width: 115px;
        height: 115px;
    }

    #vision .technology{
        width: 250px;
        height: 250px;
        top: 40px;
        right: -1vw;
        position: absolute;
        color: white;
        text-align: center;
    }

    #vision .computer{
        position: relative;
        width: 105px;
        height: 105px;
    }
    
}

@media screen and (max-width: 450px){
    
    #vision .innovation{
        width: 250px;
        height: 250px;
        top: 30px;
        left: -4vw;
        position: absolute;
        color: white;
        text-align: center;
    }
    
    #vision .word{
        position: relative;
        font-size: 17pt;
        font-family: 'Roboto Condensed'; font-weight: 700;
    }
    
    #vision .in{
        position: relative;
        font-size: 13pt;
        width: 150px;
        left: 11vw;
        font-family: 'Roboto Condensed'; font-weight: 700;
    }
    
    #vision .i{
        position: relative;
        font-size: 9pt;
        top: -15px;
        font-family: 'Roboto Condensed';
    }
    
    #vision .light{
        position: relative;
        width: 115px;
        height: 115px;
    }

    #vision .technology{
        width: 250px;
        height: 250px;
        top: 40px;
        right: -4vw;
        position: absolute;
        color: white;
        text-align: center;
    }

    #vision .computer{
        position: relative;
        width: 105px;
        height: 105px;
    }
    
}

@media screen and (max-width: 400px){
    
    #vision .think{
        position: relative;
        font-size: 10pt;
        top: -1vw;
        font-family: 'Microsoft Yahei', '微软雅黑', Arial, sans-serif; font-weight: 700;
    }
    
    #vision .ahead{
        position: relative;
        margin-right: -110px;
        font-size: 10pt;
        top: -1vw;
        font-family: 'Chalkduster';
        color: #F49D31;
    }
    
    #vision .vision{
        position: relative;
        font-size: 40pt;
        font-family: 'Chalkduster';
        color: #F49D31;
        margin-top: -5vw;
        margin-bottom: 2vw;
    }
    
    #vision .text_1{
        display: none;
    }

    #vision .text_2{
        display: block;
        position: relative;
        font-size: 15pt;
        bottom: 1vw;
        font-family: "Roboto Condensed";
    }

    #vision .bottombox{
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        bottom: 0vw;
        background-image: url(background.jpg);
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
        z-index: -2;
    }

    #vision .innovation{
        width: 200px;
        height: 200px;
        top: 20px;
        left: 0vw;
        position: absolute;
        color: white;
        text-align: center;
    }

    #vision .word{
        position: relative;
        font-size: 18pt;
        font-family: 'Roboto Condensed'; font-weight: 700;
    }
    
    #vision .in{
        position: relative;
        font-size: 14pt;
        left: 6vw;
        font-family: 'Roboto Condensed'; font-weight: 700;
    }
    
    #vision .i{
        position: relative;
        font-size: 9pt;
        top: -15px;
        font-family: 'Roboto Condensed';
    }
    
    #vision .light{
        position: relative;
        width: 100px;
        height: 100px;
    }
    
    #vision .technology{
        width: 200px;
        height: 200px;
        top: 30px;
        right: 0vw;
        position: absolute;
        color: white;
        text-align: center;
    }

    #vision .computer{
        position: relative;
        width: 90px;
        height: 90px;
    }

}
